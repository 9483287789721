.detail-padding {
  padding: 12px 38px;
}

.top-detail .detail-padding {
  padding-top: 0px;
}

.detail-item {
  margin-right: 56px;
  margin-bottom: 18px;
}

.bottom-wrapper {
  background-color: #f5f5f5;
  height: 62%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bottom-wrapper .detail-item {
  margin-top: 15px;
}

.bottom-wrapper .buttons-wrapper {
  margin-bottom: 28px;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

