.notification-container {
  /* max-height: 100px; */
}

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  /* background: #ebc172 0% 0% no-repeat padding-box; */
  /* border: 1px solid #F9ECD4; */
  background-color: #F9ECD4;
  height: 5rem;
  /* overflow: auto; */
}

.notification-icon {
  display: flex;
}

.notification-title {
  display: flex;
  flex-grow: 1;
  min-width: max-content;
}

.notification-details {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 35rem;
  max-height: 3rem;
  width: 600px;
  overflow: auto;
}