.battery-format-span {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.start-test-button {
  height: '36px';
}

.status-button, .notification-button  {
  pointer-events: none;
  height: '36px';
}